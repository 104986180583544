import {assets} from "../assets/assets";

export const creatorsList = [
   {
      name: 'CarmySpecial',
      logo: assets.creators.carmySpecial,
      priority: 1
   },
   {
      name: 'Il Profeta',
      logo: assets.creators.ilProfeta,
      priority: 1
   },
   {
      name: 'SOS Fanta',
      logo: assets.creators.sosFanta,
      priority: 1
   },
   {
      name: 'Cantarini',
      logo: assets.creators.cantarini,
      priority: 2
   },
   {
      name: 'Recosta',
      logo: assets.creators.recosta,
      priority: 2
   },
   {
      name: 'Luca Diddi',
      logo: assets.creators.lucaDiddi,
      priority: 2
   },
   {
      name: 'SeriousOrNot',
      logo: assets.creators.seriousOrNot,
      priority: 3
   },
   {
      name: 'FantaBoom',
      logo: assets.creators.fantaboom,
      priority: 3
   },
   {
      name: 'FantaBox',
      logo: assets.creators.fantaBox,
      priority: 3
   },
   {
      name: 'Il Calciologo',
      logo: assets.creators.ilCalciologo,
      priority: 3
   },
   {
      name: 'TuttoFantacalcio',
      logo: assets.creators.tuttoFantacalcio,
      priority: 3
   },
   {
      name: 'NewFantaVision',
      logo: assets.creators.newFantaVision,
      priority: 2
   },
   {
      name: 'FantaVirus',
      logo: assets.creators.fantaVirus,
      priority: 3
   },
   {
      name: 'BabboFanta',
      logo: assets.creators.babboFanta,
      priority: 3
   },
   {
      name: 'MrDelFanta',
      logo: assets.creators.mrDelFanta,
      priority: 3
   },
   {
      name: 'Auridinno',
      logo: assets.creators.auridinno,
      priority: 3
   },
   {
      name: 'Il Veterano',
      logo: assets.creators.ilVeterano,
      priority: 3
   },
   {
      name: 'Airway',
      logo: assets.creators.airway,
      priority: 3
   },
   {
      name: 'Capitan Giginho',
      logo: assets.creators.capitan,
      priority: 3
   },
   {
      name: 'Universo Del Fanta',
      logo: assets.creators.universo,
      priority: 3
   },
   {
      name: 'Fanta Domenica',
      logo: assets.creators.fantaDomenica,
      priority: 3
   },
   {
      name: 'FantaReporter',
      logo: assets.creators.fantaReporters,
      priority: 3
   },
   {
      name: 'FantaMister',
      logo: assets.creators.fabryFantaMister,
      priority: 3
   },
   {
      name: 'Amici Del 65.5',
      logo: assets.creators.amiciDel65,
      priority: 3
   },
   {
      name: 'WalkOffSport',
      logo: assets.creators.walkOffSport,
      priority: 3
   },
   {
      name: 'NotSoFanta',
      logo: assets.creators.notsofanta,
      priority: 3
   },
   {
      name: 'Laboratorio Calcistico',
      logo: assets.creators.laboratorio,
      priority: 3
   }
]
